import React from 'react';
import { b64EncodeUnicode } from '../../common/conversion/string';
import { navigate } from "gatsby";

class CTAForm extends React.Component {

  componentDidMount() {
    let form = document.getElementById('signUpDash');
    form.addEventListener('submit', submitForm);

    function submitForm(event) {
      event.preventDefault();
      let form = event.target;
      let email = form.querySelector('#email').value;
      let userId = b64EncodeUnicode(email);
      
      window.analytics.alias(userId);
      window.analytics.identify(userId, {
        email: email
      })
      window.analytics.track('Submitted Email',{
        email: email,
        component: 'waitlistCtaForm'
      });
      navigate(`/waitlist/?e=${b64EncodeUnicode(email)}`);
    }
  }

  render() {

    return (
      <div className="CTAForm row">
        <div className="col-xs-14">
          <h1 className="CTAForm-title Heading Heading-h1 light">Join the Magic.</h1>
          <hr className="green" />
        </div>
        <div className="col-xs-14 col-md-10 CTAForm-content">
          <p className="CTAForm-content-p BodyText BodyText-B1 light">{this.props.text}</p>
          <form id="signUpDash" className="CTAForm-content-form">
            <div className="InputBtnInside">
              <label className="InputBtnInside-label InputBtnInside-label-green font-mono-regular uppercase">email address</label>
              <input onFocus={this.inputOnFocus} onBlur={this.inputOnFocusOut} id="email" className="CtaForm-input Input InputGhost InputGhost-green Input-lg" required="required" type="email" name="email" spellCheck="false"/>
              <div className="InputBtnInside-button end-xs">
                <button form="signUpDash" className="BtnGhost BtnGhost-large green uppercase color-white CTAForm-submit" type="submit"><span><svg width="18" height="30" viewBox="0 0 18 30" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M-341-568H73v10985h-414z"/><path className="CTAForm-submit-svg" d="M1 1l15 13.13L1 29" strokeWidth="2"/></g></svg></span></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );

  }

  inputOnFocus() {
    let ctaForm_input_cont = document.getElementsByClassName('InputBtnInside')[0];
    ctaForm_input_cont.classList.add("focus");
  }
  inputOnFocusOut() {
    let ctaForm_input_cont = document.getElementsByClassName('InputBtnInside')[0];
    let ctaForm_input = document.getElementsByClassName('CtaForm-input')[0];
    if (!ctaForm_input.value) {
      ctaForm_input_cont.classList.remove("focus");
    }
  }

}

export default CTAForm;
