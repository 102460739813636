import React from 'react';

const InfoSectionSplit = props =>
  (<div className="InfoSectionSplit">
    <hr className={props.data[0].color ? props.data[0].color : "grey"}/>
    <div className="InfoSectionSplit-cont">
      <div>
        <h1 className="Heading Heading-h1 light InfoSectionSplit-cont-title">{props.data[0].title}</h1>
        <p className="Caption Caption-C3 light uppercase InfoSectionSplit-cont-subtitle">{props.data[0].subtitle}</p>
      </div>
      <div>
        <p className="BodyText BodyText-B1 light">{props.data[0].paragraph1}</p>
        {props.data[0].paragraph2 && <p className="BodyText BodyText-B1 light">{props.data[0].paragraph2}</p>}
        {props.data[0].paragraph3 && <p className="BodyText BodyText-B1 light">{props.data[0].paragraph3}</p>}
      </div>
    </div>
  </div>);

export default InfoSectionSplit;
