// import React from 'react';
import React from "react";
import {Link, withPrefix} from "gatsby";

class HeaderTech extends React.Component {

  render() {

    const { location } = this.props;

    const isTechpage = location === withPrefix("/tech/");
    const isCellularpage = location === withPrefix("/tech/cellular/");
    const is5Gpage = location === withPrefix("/tech/5g/");

    return (
      <div className="subnav headerTech">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <ul className="headerTech-nav">
                  <li className="headerTech-nav-item">
                    <Link
                      to="/tech/"
                      activeStyle={{
                        fontWeight: '600'
                      }}
                      className="font-mono-regular uppercase"
                    >
                      {isTechpage && <span className="headerTech-nav-item-icon"><svg width="17" height="10" viewBox="0 0 17 10"><path d="M7.256 3.86v2.39L.92 9.656V7.587l4.864-2.532L.92 2.523V.472L7.256 3.86zm8.824 0v2.39L9.744 9.656V7.587l4.864-2.532-4.864-2.532V.472L16.08 3.86z" fill="#000" fillRule="nonzero"/></svg></span>}
                      <span className="headerTech-nav-item-text">Wifi</span>
                    </Link>
                  </li>
                  <li className="headerTech-nav-item">
                    <Link
                      to="/tech/cellular/"
                      activeStyle={{
                        fontWeight: '600'
                      }}
                      className="font-mono-regular uppercase"
                    >
                      {isCellularpage && <span className="headerTech-nav-item-icon"><svg width="17" height="10" viewBox="0 0 17 10"><path d="M7.256 3.86v2.39L.92 9.656V7.587l4.864-2.532L.92 2.523V.472L7.256 3.86zm8.824 0v2.39L9.744 9.656V7.587l4.864-2.532-4.864-2.532V.472L16.08 3.86z" fill="#000" fillRule="nonzero"/></svg></span>}
                      <span className="headerTech-nav-item-text">Cellular</span>
                    </Link>
                  </li>
                  <li className="headerTech-nav-item">
                    <Link
                      to="/tech/5g/"
                      activeStyle={{
                        fontWeight: '600'
                      }}
                      className="font-mono-regular uppercase"
                    >
                      {is5Gpage && <span className="headerTech-nav-item-icon"><svg width="17" height="10" viewBox="0 0 17 10"><path d="M7.256 3.86v2.39L.92 9.656V7.587l4.864-2.532L.92 2.523V.472L7.256 3.86zm8.824 0v2.39L9.744 9.656V7.587l4.864-2.532-4.864-2.532V.472L16.08 3.86z" fill="#000" fillRule="nonzero"/></svg></span>}
                      <span className="headerTech-nav-item-text">5G</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default HeaderTech;
